<template>
  <div class="bodys">
    <div class="cont">
      <div class="cont-right">
        <div class="cont-center">
          <div class="center_top">
            <div class="top_1">总收益：</div>
            <img src="../../../../assets/img/coin.png" class="top_2" alt="" />
            <div class="top_3">{{ (income / 100).toFixed(2) }}元</div>
            <div class="date-filter">
              <span>筛选时间:</span>
              <el-date-picker
                :clearable="false"
                :editable="false"
                v-model="begin_date"
                type="month"
                placeholder="开始时间"
                @change="chooseStart"
              >
              </el-date-picker>
              <div class="line"></div>
              <el-date-picker
                :clearable="false"
                :editable="false"
                v-model="end_date"
                type="month"
                placeholder="结束时间"
                @change="chooseEnd"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="list_head">
            <div class="list_ever">订单编号</div>
            <div class="list_ever">时间</div>
            <div class="list_ever">明细</div>
            <div class="list_ever">金额</div>
          </div>
          <div class="list">
            <div class="loop" v-for="(item, index) in list" :key="index">
              <div class="loop_over">{{ item.num }}</div>
              <div class="loop_over">
                {{ (item.create_at * 1000) | format("YYYY-MM-DD HH:mm") }}
              </div>
              <div class="loop_over">{{ item.title }}</div>
              <div
                :class="
                  parseInt(item.amount) < 0 ? 'loop_over reds' : 'loop_over'
                "
              >
                {{ item.amount / 100 }}
              </div>
            </div>
          </div>

          <el-pagination
            class="page"
            :page-size="limit"
            background
            layout="prev, pager, next"
            :total="count"
            :current-page="page"
            @current-change="handleSizeChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMoneys, getMoneyRecord } from "@network/mine";
export default {
  name: "Detailed",
  data() {
    return {
      list: [],
      income: "",
      count: 0,
      page: 1,
      limit: 10,
      begin_date: "",
      end_date: "",
      startDate: "",
      endDate: "",
    };
  },
  created() {
    this.getMoneys();
    this.getMoneyRecord();
  },
  components: {},
  methods: {
    format(num) {
      if (num < 10) return "0" + num;
      return num;
    },
    //选择开始时间
    chooseStart() {
      this.startDate =
        new Date(this.begin_date).getFullYear() +
        "" +
        this.format(new Date(this.begin_date).getMonth() + 1)
      this.list = [];
      this.page = 1;
      this.getMoneyRecord();
    },
    //选择结束时间
    chooseEnd() {
      this.endDate =
        new Date(this.end_date).getFullYear() +
        "" +
        this.format(new Date(this.end_date).getMonth() + 1)
      this.list = [];
      this.page = 1;
      this.getMoneyRecord();
    },

    handleSizeChange(val) {
      this.page = val;
      this.getMoneyRecord();
    },
    //获取总收益
    getMoneys() {
      getMoneys().then((res) => {
        console.log(res);
        if (res.code != 0) {
          this.$message.error({
            message: "获取总收益失败",
            offset: 300,
          });
          return false;
        } else {
          this.income = res.data.income;
        }
      });
    },
    getMoneyRecord() {
      getMoneyRecord(this.page, this.limit,this.startDate,this.endDate).then((res) => {
        console.log(res);
        if (res.code != 0) {
          if (res.code == 403) {
            this.$storage.remove("token");
            this.$message.error({ message: "请重新登录", offset: 300 });
            this.$router.replace("/home");
            return false;
          }
          this.$message.error({
            message: "获取记录失败",
            offset: 300,
          });
          return false;
        }
        this.list = res.data;
        this.count = res.count;
      });
    },
  },
};
</script>
<style lang='less' scoped>
@import "@assets/css/vipCenter/ChildComps/detailed.less";
</style>
